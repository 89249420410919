@tailwind base;
@tailwind components;
@tailwind utilities;


html {
  @apply w-full h-full;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply w-full h-full;
}

#root {
  @apply w-full h-full;
  background-color: #fad39018;
  /* background-color: var(--token-8cf64558-7cb5-42ca-a432-3234dcb1241b, rgb(255, 255, 252)); */
  /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' viewBox='0 0 26 26'%3E%3Ccircle fill='var(--token-3cbcd694-156f-407b-a77a-fba33cbececa%2C%20rgb(234%2C%20234%2C%20241))%20%2F*%20%7B%22name%22%3A%22Light%20Gray%22%7D%20*%2F' cx='3' cy='3' r='1.5'/%3E%3C/svg%3E"); */
  background-repeat: repeat;
}



.rotate {
  animation: spin 60s linear infinite;

}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.twitter-tweet {
  @apply bg-white;
}

blockquote.twitter-tweet {
  @apply border rounded-xl mb-4 font-raleway;
  background-image: url("./assests/icons/twitter.png");
  background-repeat: no-repeat;
  background-position: top 0.5rem right 0.5rem;
  background-size: 1.25rem 1.25rem;
  padding: 1rem 2rem 1rem 1rem;
}

blockquote.twitter-tweet > p {
  margin: 0;
}